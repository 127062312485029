body{
  font-family: "Montserrat", sans-serif;
}


*{
  font-family: "Montserrat", sans-serif;
}

.card-container {
  background-color: #FFFFFF;
  border-radius: 5px;
  color: #333134;
  position: relative;
  width: 220px;
  text-align: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 20px;
  padding: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 380px;
  display: flex;
}

.card-containerrr {
  background-color: #FFFFFF;
  border-radius: 5px;
  color: #333134;
  position: relative;
  width: 300px;
  text-align: center;
  border-radius: 20px;
  padding: 20px;
  min-height: 350px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border: 2px solid #6CD6A9;
}
@media screen and (max-width: 700px) {
  .card-containerrr {
    background-color: #FFFFFF;
    border-radius: 5px;
    color: #333134;
    position: relative;
    width: 250px;
    text-align: center;
    border-radius: 20px;
    padding: 10px;
    min-height: 200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border: 2px solid #6CD6A9;
  }
}

.custom-swiper .swiper-pagination-bullet {
  background-color: #D5D5D5;
}

.custom-swiper .swiper-pagination-bullet-active {
  background-color: #6CD6A9;
}

.Reviews-card {
  background-color: #6CD6A9;
  border-radius: 5px;
  color: white;
  position: relative;
  width: 240px;
  text-align: center;
  min-height: 350px;
  border-radius: 20px;
  padding: 25px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.rev-containerrr {
  background-color: #6CD6A9;
  border-radius: 5px;
  color: #333134;
  position: relative;
  width: 350px;
  text-align: center;
  border-radius: 20px;
  padding: 20px;
  min-height: 400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border: 2px solid #7B3AE2;
}



.packages {
  background-color: #FFFFFF;
  border-radius: 5px;
  color: #333134;
  width: 260px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  min-height: 300px;
  border-radius: 20px;
}


.custom-textarea{
  background-color:white;
  border: 1px solid rgba(196, 196, 196, 0.582);
  padding:10px 1px 1px 12px;
  border-radius: 5px;
  color: #7B3AE2;
  height: 100px;
  resize: none;
  z-index: 10;
  overflow: auto;
}
.custom-textarea::placeholder {
  color: #7B3AE2;
  font-size:12px;
  font-family: "Montserrat", sans-serif;
}


.accordion-root::before {
  display: none;
}


.flag-dropdown {
  background-color: white  !important;
  border: 0.1px solid #7B3AE2 !important;
  border-radius: 5px 0px 0px 5px !important;
}





.st0{font-family:'FootlightMTLight';}
.st1{font-size:83.0285px;}
.st2{fill:gray;}


path#XMLID_5_ {
   
    fill: #16a085;
    filter: url(#blurFilter4);
}
path#XMLID_11_ ,path#XMLID_2_ {
    fill: #16a085;
}
.circle{
  animation: out 2s infinite ease-out;
  fill: #16a085;
}

#container{
  text-align:center;
}
.message{
	color:#16a085;
}
.message:after{
	content:"]";
}
.message:before{
	content:"[";
}

.message:after, .message:before {
  
  color: #16a085;
  font-size: 20px;
  -webkit-animation-name: opacity;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: opacity;
          animation-name: opacity;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
          margin:0 50px;
}

@-webkit-keyframes opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes out {
  0% {r:1;  opacity: 0.9 ;}
  25%{r:5;  opacity: 0.3 ;}
  50%{r:10; opacity: 0.2 ;}
  75%{r:15;opacity:0.1;}
 	100% {r:20;opacity:0;}
}









  
  	
